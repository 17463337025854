<template>
  <div :class="$style.detail">
    <div :class="$style.left">
      <div :class="$style.avatar">
        <img :class="$style.img" :src="data.avatar" />
        <img :class="$style.type" :src="data.platform_icon" />
      </div>
      <div>
        <div :class="$style.row">
          <div :class="$style.label">公众号名称</div>
          <div :class="$style.value">{{ data.nickname }}</div>
        </div>
        <div :class="$style.row">
          <div :class="$style.label">公众号类型</div>
          <div :class="$style.value">{{ data.type | type }}</div>
        </div>
        <div :class="$style.row">
          <div :class="$style.label">认证类型</div>
          <div :class="$style.value">{{ data.is_cert ? '已订阅' : '未订阅' }}</div>
        </div>
        <div :class="$style.row">
          <div :class="$style.label">主体信息</div>
          <div :class="$style.value">{{ data.principal_name }}</div>
        </div>
      </div>
      <div :class="$style.line" />
      <div>
        <div :class="$style.row">
          <div :class="$style.label">原始ID</div>
          <div :class="$style.value">{{ data.origin_id }}</div>
        </div>
        <div :class="$style.row">
          <div :class="$style.label">APPID</div>
          <div :class="$style.value">{{ data.app_id }}</div>
        </div>
      </div>
      <div :class="$style.line" />
      <div>
        <div :class="$style.row">
          <div :class="$style.label">官方后台</div>
          <div :class="$style.value">
            <bj-link type="primary" @click="onCall()"> 立即访问 </bj-link>
          </div>
        </div>
      </div>
      <div :class="$style.footer">
        <p>
          上次授权时间
          {{ data.authorizer_time }}
        </p>
        <bj-button :class="$style.btn" @click="getUrl()">
          <i class="ri-link left" />
          重新授权
        </bj-button>
      </div>
    </div>
    <div :class="$style.right">
      <p :class="$style.title">分发记录</p>
      <a-table :columns="columns" :loading="loading" :data-source="list" :pagination="false" row-key="id">
        <template #cover="item">
          <img :class="$style.cover" :src="item.cover.cover" />
        </template>
        <template #action="item">
          <bj-link type="primary" @click="onDetail(item)"> 详情 </bj-link>
        </template>
      </a-table>
      <div class="pagination">
        <a-pagination
          :current="page"
          show-size-changer
          :show-total="total => `共 ${total} 条`"
          :page-size.sync="pageSize"
          :total="total"
          @showSizeChange="onShowSizeChange"
          @change="onPageChange"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { spreadManageService, terminalManageService } from '@/service'

const service = new spreadManageService()
const tService = new terminalManageService()

export default {
  name: 'detail',
  filters: {
    type(value) {
      switch (value) {
        case 0:
          return '订阅号'
        case 1:
          return '服务号'
      }
    },
  },
  data() {
    return {
      loading: false,
      data: {},
      list: [],
      page: 1,
      pageSize: 10,
      total: 0,
    }
  },
  computed: {
    columns() {
      return [
        {
          title: '内容名称',
          width: 200,
          scopedSlots: {
            customRender: 'cover',
          },
        },
        { title: '内容标题', dataIndex: 'title' },
        {
          title: '内容类型',
          dataIndex: 'content_type',
          customRender: value => {
            switch (value) {
              case 'post':
                return '图文'
              case 'video':
                return '视频'
              case 'audio':
                return '音频'
              case 'image':
                return '图册'
            }
          },
        },
        { title: '发布时间', dataIndex: 'created_at' },
        {
          title: '管理',
          scopedSlots: {
            customRender: 'action',
          },
        },
      ]
    },
  },
  created() {
    this.getInfo()
    this.getList()
  },
  methods: {
    async getList() {
      const { data } = await service.getMedia({
        media_id: this.$route.query.id,
        page: this.page,
        page_size: this.pageSize,
      })
      this.list = data.record
      this.total = data.total
    },
    async getInfo() {
      const { data } = await service.getMediaInfo({
        id: this.$route.query.id,
        platform_type: 'wx',
      })
      this.data = data
    },
    onDetail() {
      //
    },
    onCall() {
      window.open(this.data.website_url)
    },
    async getUrl() {
      try {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        let _this = this
        const { data } = await tService.getUrl({
          platform_type: 'official',
          type: 'media',
        })
        this.$confirm({
          title: '授权确认',
          content: '请确认授权是否已经完成？',
          onOk() {
            _this.getInfo()
            _this.getList()
          },
          okText: '已完成',
          cancelText: '未完成',
        })
        window.open(data.auth_url)
      } catch (e) {}
    },
    onPageChange(page) {
      this.page = page
      this.getList()
    },
    onShowSizeChange() {
      this.page = 1
      this.getList()
    },
  },
}
</script>

<style lang="less" module>
.detail {
  display: flex;

  .left {
    width: 380px;
    min-height: calc(100vh - 252px);
    background: #fff;
    margin-right: 20px;
    padding: 20px;
    position: relative;

    .avatar {
      position: relative;
      width: 80px;
      height: 80px;
      margin: 30px auto 40px;

      .img {
        width: 80px;
        height: 80px;
        border-radius: 100%;
      }

      .type {
        position: absolute;
        right: 0;
        bottom: 0;
        width: 20px;
        height: 20px;
        border-radius: 100%;
      }
    }
    .row {
      display: flex;
      font-size: 13px;
      margin-bottom: 10px;

      .label {
        color: #5c5c5c;
        width: 100px;
      }

      .value {
        color: #000;
        flex: 1;
      }
    }

    .line {
      width: 100%;
      border-top: 1px solid #eee;
      margin: 10px 0 20px;
    }

    .footer {
      position: absolute;
      bottom: 20px;
      left: 0;
      width: 100%;

      p {
        text-align: center;
        font-size: 12px;
        color: #5c5c5c;
        margin-bottom: 10px;
      }

      .btn {
        width: calc(100% - 40px);
        margin-left: 20px;
      }
    }
  }

  .right {
    flex: 1;
    min-height: calc(100vh - 252px);
    background: #fff;
    padding: 20px;

    .title {
      font-size: 16px;
      color: #000;
      margin-bottom: 20px;
    }
  }
}

.cover {
  width: 70px;
  height: 44px;
  border-radius: 6px;
  object-fit: cover;
}
</style>
